@use "variables";

.toggle-menu_btn {
  color: var(--primary-color-main);
  transform: translate(7rem, -4rem);
   cursor: pointer;
  font-size: 18px;
  z-index: 100;
  /* margin-right: 3rem;
  inset: -8px -12px -8px -8px;  */
} 

.toggle-menu_btn :hover{
    color: #0065ff;
}

.toggle-menu_btn.isOpen {
  /* right: 50; */
}

.routes {
  color: rgb(35, 32, 32);
}

.sidenav-icons {
  font-size: 1.2rem;
  color: var(--primary-color-main);
}

.icon {
  font-size: 19px;
}

.toggle-btn {
  cursor: pointer;
  margin-left: 10.8rem;  

}

.toggle-btn:hover {
  transition: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  color: rgb(141, 133, 250);
}

.link-text {
  white-space: nowrap;
  font-size: 13.5px;
  padding-top: 6px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

.link {
  display: flex;
  color: #394a64;
  gap: 15px;
  padding: 5px 20px;
  transition: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover {
  border-right: 4px solid var(--primary-color-main);
  transition: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  background: rgb(237, 235, 240);
  text-decoration: none;
}

.top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}

.dash-container {
  display: flex;
  justify-content: center;
  /* margin: 1rem 1rem; */
}
/* 
.absolute {
  position: absolute;
  left: 303px;
} */

@media screen and (min-width: 1024px) {
  
}

@media screen and (min-width: 768px) {
  
}

 
.stickyBottomSideLink {
  position: fixed;
  bottom: 0;
}

//.nav-container {
// position: relative;
//  width: 100%;
//}
//
//.nav-tile__container {
//  position: absolute;
//  width: 100%;
//}
//
.nav-tile-list {
}


.nav-tile__div {
  font-family: "Roboto";
  //overflow: hidden;
  font-weight: bold;
  height: 35px;
  width: 100%;
  //border: 1px solid black;
  text-align: left;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  &.nav-tile_text {
    color: inherit;
    margin-left: 5px;
  }
}

.nav-tile__div--unselected {
  color: variables.$wellnecityPurple;
  background-color: white;
}
.nav-tile__div--selected {
  background-color: variables.$wellnecityPurple;
  color: white;
}