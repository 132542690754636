@use "variables";

html {
  --basicValue: calc(24vh - 5px);
}
.chart-card-body {
  // height: 30vh;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.chart-container {
  // height: 27vh !important;
  max-height: 30rem;
  max-width: 30rem;
  // margin-bottom: 1.5em;
  canvas {
    height: calc(var(--basicValue) * 1px) !important;
    width: calc(var(--basicValue) * 1px) !important;
  }
}

.chart-sub-text {
  font-size: 1rem;
  font-weight: 600;
  color: #5d57a6;
  display: block;
  font-family: "Roboto", SansSerif;
}

.chart-key-text {
  font-size: .8rem;
  font-weight: 600;
  display: block;
  color: variables.$wellnecityGray;
  font-family: "Roboto", SansSerif;
}

.savings-donut {
  //margin: 2.5rem;
}