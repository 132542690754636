﻿@use "variables";

.bread-crumb-skeleton {
  width: 100%;
  height: 56px;
}

.skeleton-def {
  display: inline-flex;
}

#breadcrumb {
  height: variables.$breadcrumbHeight;
  padding: variables.$breadcrumbPadding 0;
}

.bread-crumb-client {
  display: flex;
  align-items: center;
  color: #45445f;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 1.1rem;
  @include variables.xs {
    font-size: 0.9rem;
  }
  @include variables.xl {
    font-size: 1.1rem;
  }
  @include variables.wd {
    font-size: 1.1rem;
  }
}

.bread-crumb-separator {
  background-color: variables.$wellnecityGray;
  opacity: 0.3;
  width: 1px;
  height: 100%;
  margin: 0 1rem;
}

.bread-crumb-subtext {
  display: flex;
  align-items: center;
  font-family: "Roboto";
  color: variables.$wellnecityPurple;
  font-weight: 500;
  @include variables.xs {
    font-size: 0.8rem;
  }
  //@include variables.md {
  //  width: 12% !important;
  //}
  //@include variables.lg {
  //  width: 13% !important;
  //}
  @include variables.xl {
    font-size: 1rem;
  }
  @include variables.wd {
    font-size: 1rem;
  }
}

.sm-drop-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

#content-container {
  max-height: variables.$bodyHeight;
}

.pager-main {
  background-color: variables.$wellnecityPurple;
}

.bg-well {
  background-color: variables.$wellnecityAppPurple !important;
}

.react-container {
  text-align: start !important;
  & .react-select__control {
    background-color: #f9f9f9 !important;
    border: 1px solid #ced4da;
  }
}

.filter-text {
  background-color: #f9f9f9 !important;
}

#total-count-badge {
  background-color: variables.$wellnecityPurple !important;
  color: white;
  margin-right: 0.5rem;
}

#medium-text {
  background-color: variables.$wellnecityPurple70 !important;
}

#medium-phone {
  background-color: variables.$wellnecityPurple50 !important;
}

#medium-email {
  background-color: variables.$wellnecityPurple30 !important;
}

#medium-default {
  background-color: variables.$wellnecityPurple30 !important;
}

.pager-main {

}