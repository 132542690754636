﻿@use "variables";



.dash-card-title {
  font-family: "Roboto";
  font-size: 1rem;
  font-weight: 500;
  color: #2d2c49;
}

.dash-card-sub-title {
  font-size: .8rem;
  font-family: "Roboto";
  color: #5f5f5f;
  font-weight: 400;
}

.dash-card-context-menu {
  font-size: 1rem;
}

.card.dash-card {
  border-radius: 0;
}

.dash-donut {
  height: 80%;
}

.client__img {
  width: 6.5rem;
  height: 6.5rem;
  aspect-ratio: 1/1;
  object-fit: contain;
  border-radius: 50%;
}




.card.styled-card {

}

.card-header.styled-card-header {
  border-bottom: none;
  background-color: transparent;
  font-size: 1.3rem;
}

.merge-page-container {
  //height: variables.$bodyHeight;
}
$mergeActionDivHeight: 3rem;
.merge-window-container {
  position: relative;
  border: 1px solid variables.$lightGray;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 48%;
  overflow-y: hidden;
  height: calc(#{variables.$bodyHeight} - #{$mergeActionDivHeight});
  @include variables.xs {
  }
  @include variables.xl {
  }
  @include variables.wd {

  }
}

.merge-window-title-container {
  background-color: variables.$softGray;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  padding: 5px;
  height: 7%;
  display: flex;
  flex-direction: column;
  //display: inline-flex;
  //align-items: center;
  //justify-content: start;
  @include variables.xs {
  }
  @include variables.xl {
  }
  @include variables.wd {

  }
}

.merge-window-subtitle {
  font-size: 0.8rem;
  color: variables.$lightGray;
  font-weight: bold;
  margin-left: #{variables.rem(5)}rem;
}

.merge-window-footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: variables.$softGray;
  width: 100%;
  padding: 5px;
  height: 4%;
  @include variables.xs {
  }
  @include variables.xl {
  }
  @include variables.wd {

  }
}

.merge-window-actions {
  height: $mergeActionDivHeight;
  width: 100%;
  padding: 0.3rem 0;
  display: flex;
  justify-content: flex-end;
}

.merge-window-title {
  margin-left: 5px;
  font-weight: bold;
}

.merge-window-body {
  overflow-y: auto;
  height: 100%;
  padding: 1rem;
  background-color: variables.$white;
  @include variables.xs {
  }
  @include variables.xl {
  }
  @include variables.wd {

  }
}

.disabled {
  cursor: default;
  //filter: brightness(20%);
}

.bodyHeight {
  height: variables.$bodyHeight !important;
}

//.merge-item-manual {
//  background-color: rgba(64, 57, 217, 0.75) !important;
//}
//
//.merge-item-imported {
//  border-color: rgba(146, 28, 190, 0.75) !important;
//}

.merge-item-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}