@use "variables";

td {
  text-align: right !important;
  vertical-align: middle !important;
}
td:first-child {
  // text-align: left !important;
}
th {
  text-align: center !important;
  vertical-align: bottom !important;
}

td {
  vertical-align: top !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: variables.$tableStripeGray !important;
}

thead {
  height: 33px !important;
  .header-colored {
    background-color: variables.$wellnecityPurple !important;
    color: white !important;
  }
}
thead tr {
  height: 33px !important;
  & > th {
    height: 33px !important;
    padding: .25em !important;
    font-weight: normal !important;
  }
 
}

.table td, .table th {
  //vertical-align: middle !important;
  &.clientLeverTHs {
    vertical-align: text-top !important;
  }
}

.product-display-table {
  display: block;
  background: #ffffff;
  border: 1px solid #dee5ef;
  border-radius: 12px;
  box-shadow: 0px 3px 10px 2px rgba(219, 221, 224, 0.63);
  height: 70vh;
  padding: 1em;
  align-items:flex-start;
  & > .table {
    height: 80%;
    overflow-y: scroll;
  }
}
.bottomSavingsLeverButton {
  position: absolute !important;
  bottom: 16px !important;
  right: 32px !important;
}

.th-card-light {
  margin: 0 0.1rem !important;
  color: #fff;
  font-weight: lighter;
}

.th-card {
  color: #fff;
}

.th-data-row {
  //display: flex;
  //flex-direction: row;
}

.card-row {

}


.diagnostic-table-header {
  background-color: variables.$wellnecityPurple !important;
  color: #FFF;
}

.table-bordered thead th.header-align-left {
  text-align: start !important;
}

.table-bordered thead th.header-align-center {

}

.table thead th.dianosis-header {
  text-align: start !important;
  font-weight: bold !important;
}

.header-default {
  text-align: start !important;
  vertical-align: text-bottom !important;
  padding-bottom: 0 !important;
  //padding-left: 10% !important;
  float: bottom;
}


#table-edit {
  height: 60%;
  display: flex;
  align-items: center;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border-radius: 0.5rem !important;
  background-color: transparent;
  border-color: transparent;
  color: variables.$wellnecityPurple !important;
  &:hover {
    background-color: variables.$wellnecityAppPurple !important;
    color: white !important;
  }
}

tr.summary-row {
  td:first-child {
    font-weight: bold;
  }
  td {
    background-color: #F0F0F0;
    border-top: double 2px #000;
    border-spacing: 1px;
  }
}