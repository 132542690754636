//@import "_variables.scss";
@use "variables";
.lightTheme.fullHeight {
  height: 100% !important;
}

a.themeBackground.nav-link {
  background-color: variables.$offWhite !important;
  color: black;
}
a.themeBackground.nav-link.active {
  color: variables.$primaryColor !important;
  text-decoration: underline;
}

.lightTheme {
  background-color: variables.$offWhite;

  .header, .footer {
    height: 50px;
    background-color: variables.$wellnecityPurple;
  }

  .primaryText {
    color: variables.$primaryColor !important;
  }

  .primaryDarkText {
    color: variables.$wellnecityPurple !important;
  }

  .secondaryText {
    color: variables.$secondaryColor !important;
  }

  .successText {
    color: variables.$successColor !important;
  }

  .infoText {
    color: variables.$infoColor !important;
  }

  .warningText {
    color: variables.$warningColor !important;
  }

  .dangerText {
    color: variables.$successColor !important;
  }

  .menuButtonColor {
    color: variables.$white !important;
  }

  .projectPrimary, .projectPrimary.btn.btn-secondary {
    background-color: variables.$primaryColor !important;
    border-color: variables.$primaryColor !important;
    color: white !important;
    &.btn:hover {
      background-color: variables.$projectPrimaryBlueDark !important;
      color: white !important;
      &.disabled {
        background-color: variables.$primaryColor !important;
        border-color: variables.$primaryColor !important;
        color: white !important;
      }
    }
    &.btn-outline-secondary {
      background-color: #ffffff !important;
      border-color: variables.$primaryColor !important;
      color: variables.$primaryColor !important;
      &.btn:hover {
        background-color: variables.$primaryColor !important;
        border-color: variables.$primaryColor !important;
        color: white !important;
        &.disabled {
          background-color: #ffffff !important;
          border-color: variables.$primaryColor !important;
          color: variables.$primaryColor !important;
        }
      }
      &.active {
        background-color: variables.$primaryColor !important;
        border-color: variables.$primaryColor !important;
        color: white !important;
        &.btn:hover {
          background-color: variables.$primaryColor !important;
          color: white !important;
        }
      }
    }
    &.card-outline {
      background-color: transparent !important;
      border-color: variables.$primaryColor !important;
      color: variables.$primaryColor !important;
    }
    &.list-group-item {
      background-color: #ffffff !important;
      border-color: variables.$primaryColor !important;
      color: variables.$primaryColor !important;
      &.active {
        background-color: variables.$primaryColor !important;
        border-color: variables.$primaryColor !important;
        color: white !important;
        &.btn:hover {
          background-color: variables.$primaryColor !important;
          color: white !important;
        }
      }
    }
  }

  .projectDanger {
    background-color: variables.$dangerColor !important;
    border-color: variables.$dangerColor !important;
    color: white !important;
    &.btn:hover {
      background-color: variables.$projectDangerRedDark !important;
      color: white !important;
      &.disabled {
        background-color: variables.$dangerColor !important;
        border-color: variables.$dangerColor !important;
        color: white !important;
      }
    }
    &.btn-outline-secondary {
      background-color: #ffffff !important;
      border-color: variables.$dangerColor !important;
      color: variables.$dangerColor !important;
      &.btn:hover {
        background-color: variables.$dangerColor !important;
        border-color: variables.$dangerColor !important;
        color: white !important;
        &.disabled {
          background-color: #ffffff !important;
          border-color: variables.$dangerColor !important;
          color: variables.$dangerColor !important;
        }
      }
      &.active {
        background-color: variables.$dangerColor !important;
        border-color: variables.$dangerColor !important;
        color: white !important;
        &.btn:hover {
          background-color: variables.$dangerColor !important;
          color: white !important;
        }
      }
    }
    &.card-outline {
      background-color: transparent !important;
      border-color: variables.$dangerColor !important;
      color: variables.$dangerColor !important;
    }
    &.list-group-item {
      background-color: #ffffff !important;
      border-color: variables.$dangerColor !important;
      color: variables.$dangerColor !important;
      &.active {
        background-color: variables.$dangerColor !important;
        border-color: variables.$dangerColor !important;
        color: white !important;
        &.btn:hover {
          background-color: variables.$dangerColor !important;
          color: white !important;
        }
      }
    }
  }

  .textProjectDanger {
    color: variables.$dangerColor !important;
  }

  .projectInfo {
    background-color: variables.$infoColor !important;
    border-color: variables.$infoColor !important;
    color: white !important;
    &.btn:hover {
      background-color: variables.$projectInfoTealDark !important;
      color: white !important;
      &.disabled {
        background-color: variables.$infoColor !important;
        border-color: variables.$infoColor !important;
        color: white !important;
      }
    }
    &.btn-outline-secondary {
      background-color: #ffffff !important;
      border-color: variables.$infoColor !important;
      color: variables.$infoColor !important;
      &.btn:hover {
        background-color: variables.$infoColor !important;
        border-color: variables.$infoColor !important;
        color: white !important;
        &.disabled {
          background-color: #ffffff !important;
          border-color: variables.$infoColor !important;
          color: variables.$infoColor !important;
        }
      }
      &.active {
        background-color: variables.$infoColor !important;
        border-color: variables.$infoColor !important;
        color: white !important;
        &.btn:hover {
          background-color: variables.$infoColor !important;
          color: white !important;
        }
      }
    }
    &.card-outline {
      background-color: transparent !important;
      border-color: variables.$infoColor !important;
      color: variables.$infoColor !important;
    }
    &.list-group-item {
      background-color: #ffffff !important;
      border-color: variables.$infoColor !important;
      color: variables.$infoColor !important;
      &.active {
        background-color: variables.$infoColor !important;
        border-color: variables.$infoColor !important;
        color: white !important;
        &.btn:hover {
          background-color: variables.$infoColor !important;
          color: white !important;
        }
      }
    }
  }

  .projectSuccess {
    background-color: variables.$successColor !important;
    border-color: variables.$successColor !important;
    color: white !important;
    &.btn:hover {
      background-color: variables.$projectSuccessGreenDark !important;
      color: white !important;
      &.disabled {
        background-color: variables.$successColor !important;
        border-color: variables.$successColor !important;
        color: white !important;
      }
    }
    &.btn-outline-secondary {
      background-color: #ffffff !important;
      border-color: variables.$successColor !important;
      color: variables.$successColor !important;
      &.btn:hover {
        background-color: variables.$successColor !important;
        border-color: variables.$successColor !important;
        color: white !important;
        &.disabled {
          background-color: #ffffff !important;
          border-color: variables.$successColor !important;
          color: variables.$successColor !important;
        }
      }
      &.active {
        background-color: variables.$successColor !important;
        border-color: variables.$successColor !important;
        color: white !important;
        &.btn:hover {
          background-color: variables.$successColor !important;
          color: white !important;
        }
      }
    }
    &.card-outline {
      background-color: transparent !important;
      border-color: variables.$successColor !important;
      color: variables.$successColor !important;
    }
    &.list-group-item {
      background-color: #ffffff !important;
      border-color: variables.$successColor !important;
      color: variables.$successColor !important;
      &.active {
        background-color: variables.$successColor !important;
        border-color: variables.$successColor !important;
        color: white !important;
        &.btn:hover {
          background-color: variables.$successColor !important;
          color: white !important;
        }
      }
    }
  }

  .projectSecondary {
    background-color: variables.$secondaryColor !important;
    border-color: variables.$secondaryColor !important;
    color: white !important;
    &.btn:hover {
      background-color: variables.$projectSecondaryGrayDark !important;
      border-color: variables.$projectSecondaryGrayDark !important;
      color: white !important;
      &.disabled {
        background-color: variables.$secondaryColor !important;
        border-color: variables.$secondaryColor !important;
        color: white !important;
      }
    }
    &.btn-outline-secondary {
      background-color: #ffffff !important;
      border-color: variables.$secondaryColor !important;
      color: variables.$secondaryColor !important;
      &.btn:hover {
        background-color: variables.$secondaryColor !important;
        border-color: variables.$secondaryColor !important;
        color: white !important;
        &.disabled {
          background-color: #ffffff !important;
          border-color: variables.$secondaryColor !important;
          color: variables.$secondaryColor !important;
        }
      }
      &.active {
        background-color: variables.$secondaryColor !important;
        border-color: variables.$secondaryColor !important;
        color: white !important;
        &.btn:hover {
          background-color: variables.$secondaryColor !important;
          color: white !important;
        }
      }
    }
    &.card-outline {
      background-color: transparent !important;
      border-color: variables.$secondaryColor !important;
      color: variables.$secondaryColor !important;
    }
    &.list-group-item {
      background-color: #ffffff !important;
      border-color: variables.$secondaryColor !important;
      color: variables.$secondaryColor !important;
      &.active {
        background-color: variables.$secondaryColor !important;
        border-color: variables.$secondaryColor !important;
        color: white !important;
        &.btn:hover {
          background-color: variables.$secondaryColor !important;
          color: white !important;
        }
      }
    }
  }

  .projectWarning {
    background-color: variables.$warningColor !important;
    border-color: variables.$warningColor !important;
    color: white !important;
    &.btn:hover {
      background-color: variables.$projectWarningYellowDark !important;
      border-color: variables.$projectWarningYellowDark !important;
      color: white !important;
      &.disabled {
        background-color: variables.$warningColor !important;
        border-color: variables.$warningColor !important;
        color: white !important;
      }
    }
    &.btn-outline-secondary {
      background-color: #ffffff !important;
      border-color: variables.$warningColor !important;
      color: variables.$warningColor !important;
      &.btn:hover {
        background-color: variables.$warningColor !important;
        border-color: variables.$warningColor !important;
        color: white !important;
        &.disabled {
          background-color: #ffffff !important;
          border-color: variables.$warningColor !important;
          color: variables.$warningColor !important;
        }
      }
      &.active {
        background-color: variables.$warningColor !important;
        border-color: variables.$warningColor !important;
        color: white !important;
        &.btn:hover {
          background-color: variables.$warningColor !important;
          color: white !important;
        }
      }
    }
    &.card-outline {
      background-color: transparent !important;
      border-color: variables.$warningColor !important;
      color: variables.$warningColor !important;
    }
    &.list-group-item {
      background-color: #ffffff !important;
      border-color: variables.$warningColor !important;
      color: variables.$warningColor !important;
      &.active {
        background-color: variables.$warningColor !important;
        border-color: variables.$warningColor !important;
        color: white !important;
        &.btn:hover {
          background-color: variables.$warningColor !important;
          color: white !important;
        }
      }
    }
  }

  .projectDark {
    background-color: variables.$darkColor !important;
    border-color: variables.$darkColor !important;
    color: white !important;
    &.btn:hover {
      background-color: variables.$darkColor !important;
      border-color: variables.$darkColor !important;
      color: white !important;
      &.disabled {
        background-color: variables.$darkColor !important;
        border-color: variables.$darkColor !important;
        color: white !important;
      }
    }
    &.btn-outline-secondary {
      background-color: #ffffff !important;
      border-color: variables.$darkColor !important;
      color: variables.$darkColor !important;
      &.btn:hover {
        background-color: variables.$darkColor !important;
        border-color: variables.$darkColor !important;
        color: white !important;
        &.disabled {
          background-color: #ffffff !important;
          border-color: variables.$darkColor !important;
          color: variables.$darkColor !important;
        }
      }
      &.active {
        background-color: variables.$darkColor !important;
        border-color: variables.$darkColor !important;
        color: white !important;
        &.btn:hover {
          background-color: variables.$darkColor !important;
          color: white !important;
        }
      }
    }
    &.card-outline {
      background-color: transparent !important;
      border-color: variables.$darkColor !important;
      color: variables.$darkColor !important;
    }
    &.list-group-item {
      background-color: #ffffff !important;
      border-color: variables.$darkColor !important;
      color: variables.$darkColor !important;
      &.active {
        background-color: variables.$darkColor !important;
        border-color: variables.$darkColor !important;
        color: white !important;
        &.btn:hover {
          background-color: variables.$darkColor !important;
          color: white !important;
        }
      }
    }
  }
// add new items above this line
}
