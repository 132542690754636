@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin remove-focus {
  &:focus {
    outline: none;
  }
}