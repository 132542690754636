//@import "_variables";
@use "variables";

.dash-btn__container {
  display: flex;
  justify-content: space-evenly;
  padding: 2rem 0rem 0.1rem 0rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: "|" !important; // this is changing the separator between bread crumb items
}
.card {
  padding: 0 !important;
}
.cards-body {
  border-radius: 5px;
  border: 1px solid #d7d7d95c;
  box-shadow: 0px 6px 0.6rem rgb(0 0 0 / 10%);
  height: 200px; // 220px; might change back to taller card if we add more buttons or stats to card
  // background: rgb(88,83,157);
  // background: linear-gradient(0deg, rgba(88,83,157,1) 50%, rgba(138,135,187,1) 85%, rgba(172,169,206,1) 100%);
  text-decoration: none !important;
}

.cards-body:hover {
  box-shadow: 0px 6px 10px rgba(51, 51, 51, 0.242);
  transform: scale(101%);
}

.card-footer {
  background-color: inherit;
}

.background {
  &Home {
    border-color: variables.$wellnecityPurple;
    background-color: variables.$tableStripeGray;
  }
  &Client {
    border-color: variables.$wellnecityPurple;
    background-color: variables.$lightCardBlue;
  }
  &White {
    border-color: variables.$wellnecityPurple;
    background-color: white;
  }
}

.cardTitle {
  display: flex;
  color: variables.$wellnecityGray;
  //font-size: 1.3em;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-left: 1.7rem;
  text-align: left;
  font-family: "Roboto", SansSerif;
  @media (max-width: 1024px){
    font-size: 1.3rem;
  }
  @media (max-width: 768px){
    font-size: 1.1rem;
  }
}
.cardSubtitle {
  display: flex;
  color: variables.$wellnecityGray;
  margin-bottom: 0.5rem;
  margin-left: .5rem;
  text-align: left;
  font-family: "Roboto", SansSerif;
  font-weight: 400;
}

.breadcrumb {
  background-color: inherit !important;
  padding: variables.$breadcrumbPadding 0;
}

ol.breadcrumb {
  margin-bottom: 4px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.campaignStatusBadge {
  background-color: variables.$wellnecityPurple;
  color: white;
  font-size: 1em;
}

.dateRangeDashboard {
  font-size: .85em;
  color: variables.$wellnecityGray !important;
}

.dateHeaders {
  font-weight: bold;
  color: variables.$wellnecityGray70;
  &Data {
    font-weight: bold;
    color: variables.$wellnecityGray;
  }
}

.campaignCard {
  height: calc(100vh - 175px)
}
.caseCard {
  height: calc(70vh - 175px)
}

.greenMoney {
  font-weight: bold;
  color: variables.$moneyGreen;
}
.colorRed {
  color: variables.$dangerColor;
}
.colorPurple {
  color: variables.$wellnecityPurple;
}
.colorInfo {
  color: variables.$infoColor;
}

.closeRightPanel {
  position: absolute;
  right: -36px;
  display: inline;
  top: 0px;
  color: variables.$wellnecityPurple;
}

.dash-card .dash-card-header {
  color: #fff;
  font-weight: bolder;
  font-size: 1.2rem;
  font-family: "Roboto", SansSerif;
  margin-left: 0.5rem;
}

.plan-icons {
  padding-left: 0.8rem;
  aspect-ratio: 1/1;
  @include variables.lg {
    width: 68px;
    height: 68px;
  }
  @include variables.md {
    width: 68px;
    height: 68px;
  }
}

.on-blue {
  color: #00a8be;
}

//@mixin xs {
//  @media (min-width: #{$screen-dash-sm-min}) {
//    @content;
//  }
//}
//@mixin md {
//  @media (min-width: #{$screen-md-min}) {
//    @content;
//  }
//}
//@mixin md-dash {
//  @media (min-width: #{$screen-dash-md-min}) {
//    @content;
//  }
//}
//@mixin lg {
//  @media (min-width: #{$screen-lg-min}) {
//    @content;
//  }
//}
//@mixin xl {
//  @media (min-width: #{$screen-xl-min}) {
//    @content;
//  }
//}
//@mixin wd {
//  @media (min-width: #{$screen-wd-min}) {
//    @content;
//  }
//}


.dash-image {
  padding-left: 0.8rem;
  aspect-ratio: 1/1;
  @media (max-width: 1024px){
    width: 512px;
    height: 512px;
  }
  @media (max-width: 768px){
    width: 68px !important;
    height: 68px !important;
  }

}

.card-expand {
  opacity: 0.3;
}

.card.dash-card {
  box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.32);
  -webkit-box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.32);
  -moz-box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.32);
  border-radius: 0;
  border: none;
}

.btn.btn-outline-secondary.plan-toggle-buttons {
  border: 1px solid #424242;
  background-color: #f1f1f1;
  width: 100px;
  &:hover {
    background-color: #fcfcfc;
    color: #363636;
  }
  &:active {
    border: none;
    background-color: #FFF;
  }
  &:focus {
    box-shadow: none;
    background-color: #FFF;
  }
}

.btn.btn-outline-secondary.plan-btn-active {
  background-color: #fff;
  color: #545454;
}

.card.pop-card {
  margin-right: 1rem;
  background-color: #6cacdc;
  &  .card-row {
    border-bottom: 1px solid rgb(75, 154, 213) !important;
  }

  & .dash-image {
    fill: rgb(75, 154, 213);
  }
}

.card.med-card {
  margin-right: 1rem;
  background-color: #6b9263;
  & .card-row {
    border-bottom: 1px solid rgb(73, 122, 62) !important;
  }
  & .dash-image {
    fill: rgb(73, 122, 62);
  }
}

.card.pharm-card {
  background-color: #e9af52;
  & .card-row {
    border-bottom: 1px solid rgb(231, 158, 40) !important;
  }
  & .dash-image {
    fill: rgb(231, 158, 40);
  }
}



//Client Dashboard
.client-card {

}

.dash-card .dash-card-header {
  background: none !important;
  border: none;
}


.card-header.custom-header {
  background: none !important;
  border: none;
}


.card-header.custom-header {
  background: none !important;
  border: none;
}


.card-header.custom-header {
  background: none !important;
  border: none;
}

//.contact__badge {
  //display: inline-block;
  //text-align: center;
  //border-radius: 5px;
  //background-color: variables.$wellnecityPurple30 !important;
  //margin-right: 5px;
  //padding-left: 4px;
  //padding-right: 4px;
.contact__phone__badge {
  margin: 0 2px;
}
.contact__phone__value {
}
.contact__separator__badge {
  margin: 0 2px;
  //width: 2px;
}
//}

.quickfilter__container_div {
  margin: 0 4px;
  .bg-secondary.quickfilter__badge {
    background-color: #beb6f6 !important;
    text-align: center;
    .quickfilter__clear__div {
      margin: 4px;
      cursor: pointer;
      text-align: center;
      :hover {
        color: #ff0000;
      }
    }
    .quickfilter__separator__div {
      border-left: 2px solid variables.$mediumGray;
      marign: 0 6px;
    }
    .quickfilter__value__div {
      margin: 4px;
      text-align: center;
    }
  }
}