.dash-wrapper_tools {
  height: 31.5%;
  margin: 1rem 0 0 1rem;
}

.card-container_tools {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
}


.toolbox__wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  height: 100%;
}

.toolbox-img:hover {
  background: rgba(243, 246, 248, 0.664);
}

.tool-text {
  padding-left: 6px;
  padding-top: 6px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.cards-body_tools {
  position: relative;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  width: 210px;
  background: #fff;
  border-radius: 10px;
  margin: 1rem;
  top: 30%;
  left: 55%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 5px 15px 3px rgb(219 221 224 / 64%);
}

.tools-description {
  margin: 0rem 0 1rem 0;
  font-size: 0.9rem;
  color: #333;
}


.cards-img_tools img {
  height: 50px;
  width: 50px;
}

.card-name_tool {
  /* margin: 0rem 0 .3rem 0;
  padding-left: 15px; */
  color: #999;
  font-size: 1.1rem;
  font-weight: 600;
  padding-top: 0.4rem;
}

.tool-card {
  border-color: #b4b4b4;
}

.tool-btn {
  border: none;
  outline: none;
  background: linear-gradient(0deg, #6682ff, #815eff);
  padding: 0.3rem 0.6rem;
  //margin: 15px 35px 10px 35px;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  transition: all 0.2s ease-in;
  text-decoration: none;
}

/* box-shadow: 1px 1px 2rem rgba(0, 0, 0, 0.3); */

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 800px) {
}

@media (min-width: 1024px) {
  .site-container_two {
    display: flex;
  }
}

@media (min-width: 1200px) {
  .site-container_two {
    display: flex;
  }
}

.toolboxImg {
  height: 95px;
}