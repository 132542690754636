@use 'sass:math';

// custom small size for dashboardPanel.scss
$screen-dash-sm-min: 600px;

// Tablets and small desktops
$screen-md-min: 888px;

// custom medium size for dashboardPanel.scss
$screen-dash-md-min: 980px;

// Large tablets and desktops
$screen-lg-min: 1080px;

// XLarge desktops
$screen-xl-min: 1296px;

// Widescreen desktops
$screen-wd-min: 1500px;

@mixin xs {
  @media (min-width: #{$screen-dash-sm-min}) {
    @content;
  }
}
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}
@mixin md-dash {
  @media (min-width: #{$screen-dash-md-min}) {
    @content;
  }
}
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}
@mixin wd {
  @media (min-width: #{$screen-wd-min}) {
    @content;
  }
}

@function rem($px, $base: 16) {
  @return math.div($px, $base);
};



$textColor: #550202;
$darkColor: #550202;
$veryDarkColor: #333333;
$black: #262730;
$white: #FFFFFF;

$primaryColor: #2758cc;
$secondaryColor: #a9adad;
$successColor: #03a138;
$dangerColor: #c1292e;
$infoColor: #00a8be;
$royalBlue: #180375;
$offWhite: #f0f0f0;
$lightGray: #868686;
$softGray: #ececec;
$darkGray: #808080;
$mediumGray: #A7B0B7;
$warningColor: #ecd44c;


$projectPrimaryBlue: $primaryColor;
$projectPrimaryBlueDark: #1478a7;
$projectSecondaryGray: $secondaryColor;
$projectSecondaryGrayDark: #808080;
$projectSuccessGreen: $successColor;
$projectSuccessGreenDark: #0a7a2f;
$projectInfoTeal: $infoColor;
$projectInfoTealDark: #088494;
$projectWarningYellow: $warningColor;
$projectWarningYellowDark: #fdca40;
$projectDangerRed: $dangerColor;
$projectDangerRedDark: #a82428;
$linkColor: #4b9ad5;


$projectBrightBlue: #63d2ff;
$projectBrightBlueDark: #41a7cf;
$projectOrange: #f19143;
$projectOrangeDark: #cf650e;

$wellnecityOrange: #e79e28;
$wellnecityOrange70: #eebb69;
$wellnecityOrange50: #f3cf94;
$wellnecityOrange30: #f8e2be;

$wellnecityPurple: #58539d;
$wellnecityPurple70: #8a87bb;
$wellnecityPurple50: #6759ea;
$wellnecityPurple30: #cdcbe1;


$wellnecityYellow: #f0d56c;
$wellnecityYellow70: #f5e298;
$wellnecityYellow50: #f8eab6;
$wellnecityYellow30: #faf2d3;


$wellnecityGray: #484a4c;
$wellnecityGray70: #7f8182;
$wellnecityGray50: #a4a5a6;
$wellnecityGray30: #c8c8c9;

$wellnecityAppPurple: #3f3e66;

$tableStripeGray: #EDEDED;
$lightCardBlue: #D2E2F1;
$moneyGreen: #497A3E;

$headerHeight: rem(78.4);
$breadcrumbHeight: rem(62);
$breadcrumbPadding: 1;
$breadcrumbTotalHeight: calc(#{$breadcrumbHeight}rem + #{$breadcrumbPadding}rem + #{$breadcrumbPadding}rem);
$bodyHeight: calc(100vh - (#{$headerHeight}rem + #{$breadcrumbTotalHeight}));
$contentHeight: calc(100vh - (#{$headerHeight}rem + #{$breadcrumbTotalHeight}));