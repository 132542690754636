@use 'var';
@use 'core';

.szh-menu {
  user-select: none;
  color: var.$color;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.133), 0 0.6px 2px rgba(0, 0, 0, 0.1);
  min-width: 10rem;
  padding: 0.5rem 0;
  z-index: 10;

  &__item {
    display: flex;
    align-items: center;
    font-family: var(--font-family,var(--default-font-family));
    font-size: 0.9rem;
    position: relative;
    padding: 0.375rem 1.5rem;

    .szh-menu-container--itemTransition & {
      transition: {
        property: background-color, color;
        duration: 0.15s;
        timing-function: ease-in-out;
      }
    }

    &--type-radio {
      padding-left: 2.2rem;
      &::before {
        content: '\25cb';
        position: absolute;
        left: 0.8rem;
        top: 0.55rem;
        font-size: 0.8rem;
      }
    }

    &--type-radio#{&}--checked::before {
      content: '\25cf';
    }

    &--type-checkbox {
      padding-left: 2.2rem;
      &::before {
        position: absolute;
        left: 0.8rem;
      }
    }

    &--type-checkbox#{&}--checked::before {
      content: '\2714';
    }
  }

  &__submenu > .szh-menu__item {
    padding-right: 2.5rem;
    &::after {
      content: '\276f';
      position: absolute;
      right: 1rem;
    }
  }

  &__header {
    color: var.$header-color;
    font-size: 0.8rem;
    padding: 0.2rem 1.5rem;
    text-transform: uppercase;
  }
}