@use "variables";

@include media-breakpoint-up(sm) {
  .client-tab {
    border: none;
    height: 100%;
    //padding: 0 0.2rem;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    font-weight: lighter;
    background-color: transparent;
  }
  .client-tab-active {
    color: #5d57a6;
    border-bottom-color: #5d57a6;
    //border-bottom: 3px solid ;
    &:hover {
      //background-color: #eeeeee;
      text-decoration: none;
      color: #8a8996;
      border-color: #8a8996;
    }
  }
}
@include  media-breakpoint-up(md) {
  .client-tab {
    border: none;
    height: 100%;
    //padding: 0 0.5rem;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    font-weight: lighter;
    background-color: transparent;
  }
  .client-tab-active {
    color: #5d57a6;
    border-bottom-color: #5d57a6;
    //border-bottom: 3px solid ;
    &:hover {
      //background-color: #eeeeee;
      cursor: pointer;
      text-decoration: none;
      color: #8a8996;
      border-color: #8a8996;
    }
  }
}
@include media-breakpoint-up(lg) {
  .client-tab-active {
    color: #5d57a6;
    border-bottom-color: #5d57a6;
    border-bottom: 3px solid ;
    &:hover {
      //background-color: #eeeeee;
      text-decoration: none;
      color: #8a8996;
      border-color: #8a8996;
    }
  }
}
//.client-tab-active {
//  color: #5d57a6;
//  border-bottom-color: #5d57a6;
//  //border-bottom: 3px solid ;
//  &:hover {
//    //background-color: #eeeeee;
//    text-decoration: none;
//    color: #8a8996;
//    border-color: #8a8996;
//  }
//}

.client-tab-inactive {
  color: #8a8996;
  &:hover {
    //background-color: #f8f8f8;
    text-decoration: none;
    color: #6d6d70;
  }
}

.page-item.pager-list-item {
  :first-child {
    border-right: transparent !important;
  }
  :last-child {
    border-right: #848484 !important;
  }
}

.pager-disabled {
  background-color: #f0f1f2 !important;
}



.pager-icon {
  color: #484848;
  font-weight: bold;
  :disabled {
    color: #6d6d70;
  }
}

.action-row-container {
  display: flex;
  justify-content: center;
  align-self: center;
  height: 100%;
  :first-child {
    margin-right: 3px;
  }
}

.action-button {
  font-size: 1.4rem;
  padding: 3px 8px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  :disabled {
    //filter: brightness(80%);
  }
}

.hover {
  filter: brightness(70%);
}

.merge-button {
  color: variables.$darkGray;
}

.merge-item-button {
  color: variables.$projectSuccessGreen;
}

.reject-button {
  color: variables.$dangerColor;
}