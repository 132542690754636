//@import "_variables.scss";
@use "variables";

.header-logo {
  height: 2.2rem !important; 
  align-items: center;
  position: relative;
  margin-right: 0.8rem;
  margin-top: 0.3rem;
  cursor: pointer;
}

.header-row {
  height: auto;
}

.avatar {
  //padding-right: 20px;
  //margin-right: 10px;
  background: none;
  border: none;
  &:hover {
    background-color: #f8f8f8;
    cursor: pointer;
  }
}

.avatar :first-child{
  //margin: 11px 13px;
  //padding: -11px -13px;
  //d-flex align-items-center me-2
}

.main-header {
  //display: flex;
  //position: fixed;
  top: 0px;
  width: 100vw !important;
  //padding: 24px;
  //height: 68px;
  height: #{variables.$headerHeight}rem !important;
  align-items: center;
  position: sticky;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: start;
  z-index: 10;

  //z-index: 1;
}

.header-bg {
  background-color: #fff;
  border-bottom: 1px solid #e7e5e5e1;
}

.sub-header-decoration {
  background-color: #fff;
}

.sub-header {
  box-shadow: 0px 2px 4px rgba(107, 107, 107, 0.062);
  //transition: all 10s ease-in-out;
}

.header-icon_container {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-shrink: 0;
}

.header-icon {
  padding-left: 11px;
   font-size: 1rem;
  color: var(--primary-color-700);
  cursor: pointer;
}

.profile {
  font-size: 1.1rem;
  color: var(--grey-color-300);
}

.dropdown {
  position: relative;
  top: 96px;
  left: 0;
  width: 150px;
  z-index: 2;
  border: 1px solid rgb(0 0 0 / 4%);
  box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%);
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 8px 12px;
}

.dropdown li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

.main-nav-tab-active {
  border: none;
  height: 100%;
  padding: 0 20px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.1rem;
  font-weight: lighter;
  color: #5d57a6;
  display: flex;
  align-items: center;
  background-color: #eeeeee;
  &:hover {
    background-color: #eeeeee;
    text-decoration: none;
    color: #8a8996;
  }
}

.main-nav-tab-inactive {
  text-decoration: none;
  background: none;
  border: none;
  height: 100%;
  padding: 0 20px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.1rem;
  font-weight: lighter;
  color: #8a8996;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f8f8f8;
    text-decoration: none;
    color: #8a8996;
  }
}


.sub-header-link-row {
  //background-color: #f1f1f1;
  background-color: rgba(249, 249, 249, 0.95);
  //background-color: #eaeaea;
  padding-left: 0;
  padding-right: 0;
}

.slide-in {
  animation-name: slide-right;
  animation-duration: 2s;
}

@keyframes slide-right {

  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }

}

.page-title {
  padding-left: 8px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: #8a8996;
}

.separator {
//{'border-left': '1px solid #000', 'height': '100%'}
  width: 2px;
  color: #000;
  height: 100%;
}


.profile-button {
  background: none;
  &:hover {

  }
}

.no-hover {
  &:hover {
    background: none;
  }
}

.base-sub-nav {
  margin-top: 8px;
  margin-right: 10px;
  margin-left: 10px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.1rem;
  padding: 0 10px;
  &:hover {
    text-decoration: none;
    //background-color: #e8e8e8;
  }
}

.sub-nav-active {
  color: variables.$wellnecityPurple;
  &:hover {
    text-decoration: none;
    color: #8d8d8d;
  }
  &.active-sub-item:hover {
    background-color: #c0c0c0;
  }
}

.sub-nav-inactive {
  color: #8d8d8d;
  &:hover {
    text-decoration: none;
    color: #c0c0c0;
  }
}

.icon-color {
  color: #c8c8c8;
}

.active-sub-item {
  height: 2px;
  width: 100%;
  margin-bottom: 0px;
  background-color: variables.$wellnecityPurple;
}

.header-visibility {
  visibility: hidden;
}