@use "variables";




input.nu__input {
  background-color: #fdfdfd !important;
  border: 1px solid #c7c7c7 !important;
  border-radius: 5px !important;
  &:focus {
    border-color: variables.$wellnecityPurple !important;
    box-shadow: 0 0 0 0.2rem rgba(140, 132, 231, 0.3) !important;
  }
  &:disabled {
    background-color: #e9ecef !important;
  }
}

.checked-input-container {
  border: 1px solid #c7c7c7 !important;
  border-radius: 5px !important;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  //align-items: center;
  justify-content: space-between !important;
  align-items: stretch !important;

}

input.checked-input {
  border: none !important;

  //&:focus {
  //  border-color: variables.$wellnecityPurple !important;
  //  box-shadow: 0 0 0 0.2rem rgba(140, 132, 231, 0.3) !important;
  //}
  //&:disabled {
  //  background-color: #b4b4b6 !important;
  //}
}

.container-checked-input {
  @include variables.xs {
    width: 85% !important;
  }
  @include variables.md {
    width: 90% !important;
  }
  @include variables.lg {
    width: 95% !important;
  }
}

.check-input-checkbox-container {
  background-color: #f2f3f8 !important;
  padding: 2px;
  //height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  @include variables.xs {
    width: 13% !important;
  }
  @include variables.md {
    width: 12% !important;
  }
  @include variables.lg {
    width: 13% !important;
   }
  @include variables.xl {
    width: 5% !important;
  }
  @include variables.wd {
    width: 5% !important;
  }
}

input.input-checkbox {
  height: 30% !important;
  &:checked {
    accent-color: variables.$wellnecityPurple !important;
  }
}




.nuDatePickerInput {
  //border: 1px solid #6d6d70;
  //border-radius: 5px;
}

.customCalendar {
  background-color: variables.$wellnecityPurple;
}