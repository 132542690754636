//@use "_variables.scss";
//@use "_darkTheme.scss";
//@use "_lightTheme.scss";
//@use "dashboards.scss";
//@use "tables.scss";
//@use "header.scss";
//@use "_sideNav.scss";
//@use "_charts.scss";
//@use "_buttons.scss";
//@use "sz_index";
//@use "../../node_modules/react-loading-skeleton/dist/skeleton";


@use "variables";

html,
body,
#root,
.full-height {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  // background-color: variables.$white;
  background-color: #f2f3f8,
}:root {
  /* PRIMARY COLORS */
  --primary-color-50: #ede3ff;
  --primary-color-100: #e8dcfe;
  --primary-color-200: #d2bbff;
  --primary-color-300: #bda9f4;
  --primary-color-400: #9783d2;
  --primary-color-500: #7a68c2;
  --primary-color-main: #58539d;
  --primary-color-700: #312b81;

  /* GREYS */

  --grey-color-100: #f6f9fd;
  --grey-color-200: #dee5ef;
  --grey-color-300: #a4b0c0;
  --grey-color-400: #5e6871;
  --grey-color-500: #394a64;
  --grey-color-600: #293854;
  --grey-color-700: #192b46;
  --grey-color-800: #0a0b0c;
  --grey-color-900: #051226;

  /* LINKS */

  --link-color-1: #4b9ad5;

  /* WHITES */

  --white-color-1: #ffffff;

  /* SUCCESS COLORS */
  --success-color-50: #48EACF;
  --success-color-100: #2EE7C8;
  --success-color-200: #1ADEBD;
  --success-color-300: #17C4A7;
  --success-color-400: #14AA91;
  --success-color-500: #118F7A;
  --success-color-main: #0E7564;
  --success-color-700: #0B5C4E;
}

body {
  //overflow: scroll;
  -ms-overflow-style: none;
  /* scrollbar-width: none; */
  box-sizing: border-box;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.center-text {
  text-align: center;
}

.app-purple {
  color: variables.$wellnecityAppPurple;
}

.purple {
  color: variables.$wellnecityPurple;
}

/* TABLES */
.styled__table {
  border-collapse: collapse;
  margin-right: 2rem;
  margin-top: 1.3rem;
  margin-bottom: 0.5rem;
  font-size: 1em;
  width: 100%;
}

.styled__table thead tr {
  background: linear-gradient(0deg, #7069da, #58539ded);
  color: #ffffff;
  text-align: center;
}

.styled__table th,
.styled__table td {
  text-align: left;
  padding: 1.5em 1em 1em 1.5em;
}

.styled__table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled__table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled__table tbody tr:last-of-type {
  border-bottom: 2px solid #8FE27C;
}

.styled__table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

/* CONTAINERS */

.shadow__bar {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 0px;
}

.scroll__shadow {
  background: #009879;
  width: 40px;
  position: sticky;
  opacity: 1;
  pointer-events: auto;
  /* transition: opacity 0.2s ease 0s; */
  z-index: 90;
}

.child-container__site {
  display: flex;
  flex-direction: column;
  /* position: absolute; */
  height: 100%;
  width: 100%;
  align-items: flex-start;
  overflow: hidden;
  overflow-x: hidden;
}


.client-container__site {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 90%;
  width: 100vw;
  align-items: flex-start;
  overflow: scroll;
  margin-top: 0;
}

.container__toolbox {
  display: flex;
  position: absolute;
  height: calc(96vh - 175px);
  padding-top: 2rem;
  overflow-y: scroll !important;
}

.container__toolbox::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.container__toolbox {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}

/* BUTTONS */
.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.edit-btn_container {
  /* display: grid;
  width: 100%;
  justify-content: flex-end;
  grid-template-columns: 1fr auto; */
  display: flex;
}
.edit-btn {
  border: none;
  outline: none;
  background: linear-gradient(0deg, #6682ff, #815eff);
  padding: 0.4rem 1.8rem;
  margin: 1rem 1rem 1px 1rem;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 0.8rem;
  transition: all 0.2s ease-in;
  text-decoration: none;
}

.edit-btn2 {
  border: 1px solid #46c453 ;
  background: linear-gradient(0deg, #46c453, #46c453);
  padding: 0.4rem 1.8rem;
  margin: 1rem 1rem 1px 1rem;
  border-radius: 5px;
  cursor: pointer;
  color: #46c453;
  font-size: 0.8rem;
  transition: all 0.2s ease-in;
  text-decoration: none;
}

.edit-btn a {
  color: #ffffff;
  text-decoration: none;
  background-color: transparent;
}

/* ---------- LOGIN PAGE CSS ----------- */
.h1 {
  font-size: 10px;
}
.splitScreen {
  display: flex;
  flex-direction: column;
}

.left,
.right {
  display: flex;
  justify-content: center;
  align-items: center;
}
.left {
  height: 200px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),
    url("../assets/images/blanklogin.png");
  background-size: cover;
}
.logo {
  height: 155px;
}
.right .copy {
  margin: 1.5rem 0;
  text-align: center;
}
.right .copy p {
  margin: 1.5rem 0;
  font-size: 0.875rem;
}
.right form {
  width: 328px;
}
form input[type="text"],
form input[type="password"] {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  //border: 1px soild #c4c4c4;
  padding: 1em;
  font-size: 0.875rem;
}
label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
}
.input-container.password {
  position: relative;
}
.input-container.password i {
  position: absolute;
  top: 42px;
  right: 16px;
}

@media screen and (min-width: 900px) {
  .split-screen {
    display: flex;
    height: 100vh;
  }
  .left,
  .right {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: row;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
  height: 100%;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  align-items: flex-end;
}

.auth__template {
  //display: flex;
  //flex-direction: row;
  //width: 100%;
  //height: 100%;
}

.auth__template_ {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.container-main {
  display: flex;
  flex-direction: column;
}

.auth__flexbox {
  //margin-top: 1rem;
  width: 100%;
  //height: 100%;
}

.switch__style {
  display: flex;
  flex-direction: row;
  //margin: 4rem 1rem 1em 225px;
  //height: calc(95vh - 50px);
}

.siteContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-right: 0;
}

.contentWithHeaderPublic {
  flex: 1;
  padding: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 50px !important;
}

.contentWithHeader {
  flex: 1;
  padding: 5px !important;
  padding-top: 0px !important;
  padding-left: 65px !important;
  padding-bottom: 50px !important;
}

.footerAuth {
  min-height: 45px;
  flex: none;
  z-index: 2;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  padding-left: 65px !important;
}

.headerDesktop {
  min-height: 65px !important;
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  padding-left: 65px !important;
}

.stickyLeftMenu {
  z-index: 100;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: variables.$wellnecityPurple;
}

.stickyDupButton {
  z-index: 100;
  position: fixed;
  top: 10px;
  right: 15px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}

.stickyLeftMenuCollapsed {
  width: 60px;
  display: flex;
  flex-direction: column;
}

.leftMenuButtons {
  height: 48px !important;
  width: 48px !important;
  padding-left: 9px !important;
  &:hover {
    color: variables.$wellnecityOrange !important;
  }
}
.notificationBellButton {
  height: 36px !important;
  width: 36px !important;
}
.messageCenterPopout {
  border: 0;
  max-width: 750px;
  max-height: 75vh;
}
.barsButton {
  height: 38px !important;
  width: 38px !important;
  padding-left: 12px !important;
}

.topLeftMenuSection {
  flex: 1;
}

.bottomLeftMenuSection {
  flex: none;
}

.desktopContainer {
  min-height: 93vh;
  padding-left: 65px;
  &Public {
    padding-left: 0px;
  }
}

.mobilePreviewContainer {
  max-height: 70vh;
  padding-left: 0;
  padding-right: 0;
}

.bgRed {
  background-color: red;
}
.fullHeight {
  min-height: 90vh;
}

.filter,
.sort-handle {
  cursor: pointer;
}

.filterableScreen {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
}

.filterPagerHeader {
  /* min-height: 65px; */
  flex: none;
  padding: 0;
}
.filter-definition-caption {
  padding-top: 8px;
  display: inline-block;
}

.contentWithFilters {
  flex: 1;
  padding: 0;
}

.pagerFooter {
  min-height: 65px;
  flex: none;
}

.minWidth85 {
  min-width: 85px !important;
}

.minWidth105 {
  min-width: 105px !important;
}
.maxWidth105 {
  max-width: 105px !important;
}
.maxWidth245 {
  max-width: 245px !important;
}
.minWidth150 {
  min-width: 150px !important;
}

.mapSection {
  height: 32vh;
}

.mapView {
  height: 30vh;
}

#root
  > div
  > div.contentWithHeader
  > div
  > div
  > div
  > div.m-0.p-0.fullHeight.row
  > div
  > div
  > div.contentWithFilters
  > div.mapSection
  > div {
  // overflow: visible !important;
  overflow-x: hidden !important;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  overflow-y: hidden;
}

th {
  border-top: none !important;
}

.inputGroupPrependAddon {
  min-width: 142px !important;
}

.routeDetailsCol {
  min-width: 400px !important;
}

.minWidth750 {
  min-width: 750px !important;
}

.minWidth800 {
  min-width: 800px !important;
}

.minHeight700 {
  min-height: 700px !important;
}

.minHeight150 {
  min-height: 150px !important;
}

.minHeight225 {
  min-height: 225px !important;
}

.minHeight38 {
  min-height: 38px !important;
}

.react-time-picker__wrapper {
  border-radius: 0.25rem !important;
  border: 1px solid #ced4da;
}
.fontSize85 {
  font-size: 0.85em !important;
}

.width50Percent {
  width: 50% !important;
}
input[type="checkbox"] {
  -ms-transform: scale(1.75); /* IE */
  -moz-transform: scale(1.75); /* FF */
  -webkit-transform: scale(1.75); /* Safari and Chrome */
  -o-transform: scale(1.75); /* Opera */
  transform: scale(1.75);
  // padding: 10px;
  // margin-left: 0.45em;
  border: 1px solid #d3d3d3;
}

.showDanger {
  background-color: variables.$dangerColor !important;
  color: white !important;
}

.showWarning {
  background-color: variables.$warningColor !important;
}

.lightGreyBackground {
  background-color: #d8d8d8 !important;
}

.whiteBackground {
  background-color: #fff !important;
}

.height38 {
  min-height: 38px !important;
}

.statCard {
  padding-top: 16px !important;
  padding-top: 12px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  > div.row {
    > .card-title {
      font-size: 1.65em;
      color: variables.$primaryColor;
    }
    > .card-stat {
      font-size: 1.45em;
      color: variables.$infoColor;
    }
  }
}

.card-widget {
  min-height: 100px !important;
}

.link-panel {
  border: solid 2px variables.$infoColor;
  background-color: white;
  color: black !important;
  font-size: 1.5em !important;
  &:hover {
    background-color: variables.$offWhite !important;
  }
}

.clickable-icon {
  cursor: pointer;
  margin-left: 4px;
  &:hover {
    filter: brightness(70%);
  }
}

.red {
  color: variables.$projectDangerRedDark !important;
}

.green {
  color: variables.$successColor !important;
}

.editor {
  text-align: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.no-decoration {
  text-decoration: none !important;
}

.sidebarLogo {
  max-width: 125px !important;
}

.signOutButton {
  padding-left: 0px !important;
  padding-right: 7px !important;
  display: block;
}

.publicPagePadRight {
  padding-right: 65px !important;
}

.flipHorizontal {
  transform: scale(-1, 1);
}

.signInImage {
  max-width: 400px !important;
}
.nav-link {
  cursor: pointer;
}
.subscription {
  cursor: pointer;
}
.selectedSubscription {
  border-color: variables.$dangerColor !important;
}

.danger {
  background-color: variables.$dangerColor !important;
  color: white !important;
}

.primary {
  background-color: variables.$primaryColor !important;
  color: white !important;
}

.info {
  background-color: variables.$infoColor !important;
  color: white !important;
}

.warning {
  background-color: variables.$warningColor !important;
  color: white !important;
}

.dark {
  background-color: variables.$darkColor !important;
  color: white !important;
}

.text-warning {
  color: variables.$warningColor !important;
}

text-royal {
  color: variables.$royalBlue !important;
}
.pricing-card {
  text-align: center;
}
.price {
  font-size: 24px;
}
.bold {
  font-weight: bolder;
}
#userCircle {
  color: variables.$royalBlue !important;
  width: 200px;
  height: 200px;
  text-shadow: 1px 1px 1px;
  border-color: variables.$royalBlue !important;
}
.center-text {
  text-align: center;
}
img.user-profile-pic {
  width: 100px;
}
.dim-text {
  font-size: smaller;
  color: gray;
}
.tagline {
  text-align: center;
  color: variables.$primaryColor !important;
  font-weight: bold;
  font-size: 24px;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  border: none;
  cursor: pointer;
}

.dropdown-content:hover,
.dropdown-content:focus {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.dropdown-content {
  position: relative; // absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  //box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  display: block;
  padding: 12px 16px;
  cursor: pointer;
  color: variables.$royalBlue !important;
}
.title {
  font-size: 1.4em;
  color: variables.$primaryColor;
}
.information {
  font-size: 14px;
  font-style: italic;
  color: #737373;
}
.deactivate {
  text-decoration: line-through;
  opacity: 0.5;
}

.xs {
  padding: 2px 6px !important;
  font-size: 0.8rem !important;
}

input.dtp {
  width: 115px;
  margin-right: 6px;
}

input.time-input {
  font-size: 0.8rem;
  width: 92%;
  padding: 4px 2px;
  float: left;
  margin-top: 8px;
}
.dayOfMonth {
  width: 60px;
}

.subtext {
  font-style: italic;
  font-weight: bold;
}
label {
  margin-bottom: 0;
}
.fontSmall {
  font-size: small;
}

/*custom radio buttons*/
#wellnecityRadioSelected {
  background-color: variables.$wellnecityPurple;
  color: variables.$offWhite
}
#wellnecityRadioUnselected {
  background-color: variables.$wellnecityGray30;
  color: variables.$wellnecityPurple
}

/* The container */
.radio-container {
  display: block;
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* larger custom radio buttons */
.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #d3d3d3;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .radio-checkmark {
  // background-color: rgb(0, 92, 200);
  background-color: rgb(25, 118, 255);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {
  top: 0.45rem;
  left: 0.45rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: white;
}

// surveys
.scroll-container {
  margin-top: 1rem;
  height: 100vh;
  overflow-y: scroll;
  padding-top: 3rem;
  padding-bottom: 9rem;
  position: fixed;
  right: 0;
  width: 100% !important;
}

.edit-scroll-container {
  height: 65vh;
  overflow-y: scroll;
  position: relative;
  right: 0;
  width: 100% !important;
}

.preview-scroll-container {
  height: 57vh;
  overflow-y: scroll;
  position: relative;
  right: 0;
}

@media (max-width: 600px) {
  .scroll-container,
  .submit-section {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

/* Hide scrollbar for IE, Edge and Firefox */
.edit-scroll-container,
.scroll-container,
.preview-scroll-container,
.productsRow {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar everywhere else */
.preview-scroll-container::-webkit-scrollbar,
.edit-scroll-container::-webkit-scrollbar,
.scroll-container::-webkit-scrollbar {
  display: none;
}

.question-section-wrapper {
  margin-bottom: 1rem;
}

.survey-check-label {
  margin-left: 0.5rem;
}

.survey-check-label input[type="checkbox"] {
  margin-right: 1rem;
}

div.invalid-feedback {
  display: block;
}

img.emoji-image {
  height: 100px;
  width: 100px;
}

.ql-image-sm {
  max-height: 55px !important;
  min-height: 35px !important;
}

.ql-image-md {
  max-height: 150px !important;
  min-height: 75px !important;
}

.ql-image-lg {
  max-height: 300px !important;
  min-height: 150px !important;
}

img#Logo {
  width: calc(60vw - 35px) !important;
}

div.mobilePreviewContainer.container-fluid > div > div > div.curvedHeader {
  width: 108%;
  height: 10%;
  left: -4%;
  flex: none;
  z-index: 2;
  position: relative;
  top: 0;
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 20%;
}

.darkPurpleFooter {
  min-height: 45px;
  flex: none;
  z-index: 2;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  padding-left: 65px !important;
}

span.radio-checkmark {
  border-radius: 25% !important;
  border: 2px solid #777777 !important;
}

.radio-container .radio-checkmark:after {
  top: 0.4rem;
  left: 0.37rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: white;
}

.radio-container input:checked ~ .radio-checkmark {
  background-color: green;
}

.custom-control-label {
  padding-top: 0.25rem;
  padding-left: 0.5rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: green;
  background-color: green;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 25% !important;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 24px;
  height: 24px;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 2px solid #777777 !important;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -24px;
  display: block;
  width: 24px;
  height: 24px;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

.react-datepicker__month-container,
.react-datepicker__month,
.react-datepicker,
.react-datepicker-popper {
  z-index: 5000 !important;
}

.paddingTopForHeader {
  padding-top: 10vh !important;
}

.roundedLeftGroupButton {
  border-top-left-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
}
.filter-caption {
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  // opacity: 0.6;
}

.stickyBottomSideLink {
  position: fixed;
  bottom: 0;
}

div.styled-checkbox {
  border: none;
  background-color: variables.$darkGray;
  &.selectAll {
    background-color: white;
  }
}

div.styled-checkbox.checked {
  background-color: variables.$wellnecityAppPurple;
}
.styled-checkbox {
  width: 24px;
  height: 24px;
  //background: #e9e9e9;
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  &.selectAll {
    background-color: white;
  }
  & > input[type="checkbox"]:before {
    background: #e9e9e9;
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    width: 24px;
    height: 24px;
  }
}

.pretty.styled-checkbox {
  width: 24px;
  height: 24px;
  //background: #e9e9e9;
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  //display: -webkit-inline-box;
  &.selectAll {
    background-color: white;
  }
  & > input[type="checkbox"]:checked {
    visibility: hidden !important;
    border-radius: 8px;
  }
  & > img,
  svg {
    position: relative;
    top: -1px;
    left: -26px;
    color: white !important;
    border-radius: 8px;
  }
}

.pretty.styled-checkbox input[type="checkbox"] {
  visibility: hidden !important;
  border-radius: 8px;
}

.pretty.styled-checkbox > input[type="checkbox"]:checked:before {
  width: 24px;
  height: 24px;
  //background: variables.$wellnecityPurple;
  background-color: variables.$wellnecityPurple;
  border: 1px solid variables.$wellnecityPurple;
  border-radius: 8px;
  &.selectAll {
    background-color: white;
  }
}
.pretty.styled-checkbox.checked,
.pretty.styled-checkbox.checked input[type="checkbox"] {
  width: 24px;
  height: 24px;
  //background: variables.$wellnecityPurple;
  background-color: variables.$wellnecityAppPurple;
  border: 1px solid variables.$wellnecityAppPurple;
  border-radius: 8px;
  // margin-top: -24px !important;
}

.collapsibleColButton {
  color: variables.$wellnecityGray;
  height: 48px;
  width: 48px;
  align-content: center;
  &.disabled {
    color: variables.$wellnecityGray50 !important;
  }
}

.pager-disabled {
  background-color: #efefef !important;
}

.cursorPointer {
  cursor: pointer !important;
}

.minHeight50 {
  min-height: 50px;
}

.maxWidth200 {
  max-width: 200px;
}

button.btn.btn-primary.wellnecityBtn {
  border: none !important;
  padding: 0.4rem 1rem 0.5rem 0.8rem !important;
  &Primary {
    background: variables.$wellnecityPurple !important;
    border-color: variables.$wellnecityPurple !important;
  }
  &Secondary {
    background: variables.$wellnecityGray70 !important;
    border-color: variables.$wellnecityGray70 !important;
  }
  &Warning {
    background: variables.$wellnecityOrange70 !important;
    border-color: variables.$wellnecityOrange70 !important;
    color: variables.$wellnecityGray !important;
  }
}

.btn-group-sm,
.btn-group {
  & > button.btn.btn-primary.wellnecityBtn {
    border-radius: 0.25rem !important;
    margin: 4px !important;
  }
}

.btn:hover {
  box-shadow: 0px 6px 10px rgba(51, 51, 51, 0.242);
}

.container,
.container-fluid {
  width: 100%;
  position: relative;
  right: 0;
}

h3 {
  font-size: 26px;
  margin-top: 1rem;
  text-align: start;
}

.noWrap {
  white-space: nowrap;
}

.nodeTreeItem {
  font-size: 16px !important;
  .rct-title {
    font-size: 16px !important;
  }
  .rct-icon {
    font-style: normal;
    // > svg {
    //   height: 48px !important;
    //   width: 48px !important;
    // }
  }
  .rct-node-icon:empty {
    display: none !important;
  }
  .rct-checkbox {
    height: 2em !important;
  }
  .rct-checkbox:empty {
    display: none !important;
  }
  .rct-collapse *,
  .rct-checkbox * {
    width: 2em !important;
    height: 2em !important;
  }
}

.backgroundLightBlue {
  background-color: variables.$lightCardBlue;
}

fieldset {
  width: 100%;
}

input.form-control,
form-control {
  //background-color: #fdfdfd;
  border-radius: 0 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;

}



.calendarDatePicker {
  position: relative;
  right: 15px;
  bottom: 30px;
  color: variables.$wellnecityPurple;
}

// .datePickerInput,
// .react-datepicker__input-container {
//   max-width: 245px !important;
//   max-height: 62px !important;
// }

.datePickerInput, 
.datePickerInput > div.react-datepicker-wrapper, 
.datePickerInput > div > div.react-datepicker__input-container
.datePickerInput > div > div.react-datepicker__input-container input {
   width: 100%;
  margin-bottom: 0;

}

.MuiFormGroup-row {
  width: 100% !important;
}

.wcitySelect,
.wcitySelect--is-disabled,
.wcitySelect__control,
.wcitySelect__control--is-disabled {
  width: 100% !important;
  border-radius: 0 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  text-align: left !important;

}

.wcityNuSelect,
.wcityNuSelect__control {
  border-color: #c7c7c7 !important;
  width: 100% !important;
  text-align: left !important;
  border-radius: 5px !important;
  margin-bottom: 5px !important;
  background-color: #fdfdfd !important;
}
.wcityNuSelect__control--menu-is-open {
  background-color: #fdfdfd !important;
  width: 100% !important;
  text-align: left !important;
  border-radius: 5px !important;
  margin-bottom: 5px !important;
  border-color: variables.$wellnecityPurple !important;
  box-shadow: 0 0 0 1px rgba(140, 132, 231, 0.6) !important;
}
.wcityNuSelect__control--is-disabled,
.wcityNuSelect--is-disabled {
  border-color: #c7c7c7 !important;
  width: 100% !important;
  text-align: left !important;
  border-radius: 5px !important;
  margin-bottom: 5px !important;
  background-color: #e9ecef !important;
}
//.wcityNuSelect--is-disabled,
//.wcityNuSelect__control,
//.wcityNuSelect__control--is-disabled {
//  width: 100% !important;
//  text-align: left !important;
//  border-radius: 5px !important;
//  margin-bottom: 5px !important;
//}
//.wcityNuSelect__control--is-focused,
//.wcityNuSelect__control--menu-is-open {
//    border-color: variables.$wellnecityPurple !important;
//    box-shadow: 0 0 0 1px rgba(140, 132, 231, 0.6) !important;
//}
//.wcityNuSelect,
//.wcitySelect--is-disabled,
//wcityNuSelect__control--is-disabled {
//  background-color: #e9ecef !important;
//}

.pageSubtitle {
  color: variables.$wellnecityPurple;
  font-size: 1em;
  text-align: left;
  margin-top: -10px;
  font-style: italic;
}

.modal-header {
  border-bottom: none !important;
}
.modal-footer {
  border-top: none !important;
}
.modal-open {
  padding: 0;
  padding-right: 0!important;
}

.clickableRow:hover {
  text-decoration: underline !important;
  color: variables.$projectBrightBlueDark !important;
}

.react-datepicker__input-container {
  float: left;
}

.bg-purple {
  background-color: variables.$wellnecityPurple;
  &30 {
    background-color: variables.$wellnecityPurple30;
  }
  &50 {
    background-color: variables.$wellnecityPurple50;
  }
  &70 {
    background-color: variables.$wellnecityPurple70;
  }
}

.roundedCorners {
  border-radius: 4px;
}

a.breadcrumb-item {
  color: variables.$linkColor;
  &:hover {
    color: #0056b3;
  }
}

.main-content-container {
  background-color: #fff;
}

.row.no-space {
  padding: 0;
  margin: 0;
}

.back-drop {
  background-color: #f2f3f8;
  height: variables.$bodyHeight;
}

.dropzone {
  background-color: variables.$softGray;
}

.styled-section {
  // border-bottom: 1px solid variables.$wellnecityGray50;

  h5 {
    width: 100%;
    color: variables.$wellnecityPurple;
    // text-align: center;
    border-bottom: 1px solid variables.$wellnecityGray50;
    line-height: 0.1em;
    margin: 10px 0 25px;
    padding-left: 25px;
  }

  h5 span {
    background-color: white;
    padding: 0 10px;
  }

}
.surrounding-borders-hidden {
  border-top-style: hidden !important;
  border-bottom-style: hidden !important;
  border-left-style: hidden !important;
  border-right-style: hidden !important;
}

.scrollhost::-webkit-scrollbar {
  display: none;
}

.scrollhost {
  overflow-y: scroll;
  max-height: 480px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}

.tooltip {
  margin: 0 !important;
  padding: 0 !important;
  min-width: 150px !important;
}

.tooltip-option {
  text-align: left;
  padding: 10px;
  width: 100% !important;
}

.tooltip-option:hover {
  background-color: #f2f3f8;
  cursor: pointer;
}

.selection-row {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.selection-row .pill {
  display: flex;
  justify-content: center;
  border: 1px solid variables.$wellnecityGray30;
  border-radius: 25px;
  width: 28%;
  // min-width: 135px;
}

@media (max-width: 1500px) {
  .selection-row .pill {
    width: 45%;
    margin-bottom: 2%;
  }
}

.selection-row .pill:hover {
  cursor: pointer;
}

.selection-row .selected-pill {
  background-color: variables.$wellnecityAppPurple;
  color: white;
}

.notification-pill {
  display: flex;
  justify-content: space-between;
  border-left: 10px solid variables.$wellnecityAppPurple;
  align-items: stretch;
  width: 100%;
  background-color: #f2f3f8;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 20px;
}

.notification-pill-archived {
  background-color: variables.$tableStripeGray;

}

.notification-pill-message {
  display: flex;
  align-items: center;
  padding: 25px;
}

.notification-pill-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.switch {
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
}

.count-circle {
  height: 25px;
  width: 25px;
  background-color: variables.$wellnecityAppPurple;
  border-radius: 50%;
  display: inline-block;
  color: white;
  text-align: center;
  justify-content: center;
  // align-items: center;
}

.selectWithToolTipSpacing{
  width: 85% !important;
  margin-right: 1%;
}

// .selectToolTip{
//   width: 5%;
// }

// ul.leaders {
//   padding: 0;
//   overflow-x: hidden;
//   list-style: none
//   }
  
//   ul.leaders li:before {
//   float: left;
//   width: 0;
//   white-space: nowrap;
//   // content:
//   // ". . . . . . . . . . . . . . . . . . . . "
//   // ". . . . . . . . . . . . . . . . . . . . "
//   // ". . . . . . . . . . . . . . . . . . . . "
//   // ". . . . . . . . . . . . . . . . . . . . "
//   // ". . . . . . . . . . . . . . . . . . . . "
//   // ". . . . . . . . . . . . . . . . . . . . "


//   }
  
//   ul.leaders span:first-child {
//   padding-right: 0.33em;
//   background: white
//   }
  
//   ul.leaders span + span {
//   float: right;
//   padding-left: 0.33em;
//   background: white
//   }

[role="tooltip"],
.hidetooltip.hidetooltip.hidetooltip + [role="tooltip"] {
  visibility: hidden;
  position: absolute;
  top: 2rem;
  left: 2rem;
  background: black;
  color: white;
}
[aria-describedby]:hover,
[aria-describedby]:focus {
  position: relative;
}
[aria-describedby]:hover + [role="tooltip"],
[aria-describedby]:focus + [role="tooltip"] {
  visibility: visible;
}